import loadable from "@loadable/component";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../components/Reusable/Spinner";
import APIS from "../../constant/EndPoint";
import useGetHook from "../../customHooks/useGetHook";
import { patchApiFormData } from "../../helpers/AxiosInstance";
const EditClientComponent = loadable(
  () => import("../../components/Client/EditClient")
);
const EditClient = () => {
  const [openColorUploadPopup, setColorUploadPopup] = useState<any>(false);
  const { id } = useParams();
  const { data: clientData, isLoading: isClientDataLoading } = useGetHook({
    queryKey: [`client-${id}`],
    url: `${APIS.CLIENT}${id}/`,
    params: {},
  });
  const handleEdit = async (values: any, setError: any) => {
    const formData = new FormData();
    if (values?.logo?.[0]) {
      formData.append("logo", values?.logo?.[0]);
    }
    formData.append("name", values?.name);
    formData.append("country", values?.country);
    formData.append("email", values?.email);
    formData.append("timezone", values?.timezone);
    formData.append("currency", values?.currency);
    formData.append("mobile_number", values?.mobile_number ?? null);
    formData.append("business_name", values?.business_name);
    formData.append("address", values?.address ?? "");
    if (values?.favicon_icon?.[0]) {
      formData.append("favicon_icon", values?.favicon_icon?.[0]);
    }

    try {
      let url = `${APIS.CLIENT}${id}/`;
      const res = await patchApiFormData({ url, formData, setError });
      if (res.status === 200) {
        toast.success("Updated Successfully");
      }
    } catch (e) {
      console.log(e);
      toast.success("Somethings went wrongs");
    }
  };
  return (
    <>
      <div>EditClient</div>
      {isClientDataLoading ? (
        <Spinner />
      ) : (
        <EditClientComponent
          openColorUploadPopup={openColorUploadPopup}
          setColorUploadPopup={setColorUploadPopup}
          handleEdit={handleEdit}
          clientData={clientData}
        />
      )}
    </>
  );
};

export default EditClient;
